var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "결재요청 정보", topClass: "topcolor-orange" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.popupParam.approvalDocType == "TODO"
                          ? _c("c-btn", {
                              attrs: { label: "반려", icon: "remove" },
                              on: { btnClicked: _vm.returnApprRequest },
                            })
                          : _vm._e(),
                        _vm.popupParam.approvalDocType == "TODO"
                          ? _c("c-btn", {
                              attrs: { label: "결재", icon: "save" },
                              on: { btnClicked: _vm.saveApprRequest },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "결재 업무명",
                              name: "approvalTypeName",
                            },
                            model: {
                              value: _vm.data.approvalTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "approvalTypeName", $$v)
                              },
                              expression: "data.approvalTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              required: true,
                              label: "결재 요청명",
                              name: "approvalRequestName",
                            },
                            model: {
                              value: _vm.data.approvalRequestName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "approvalRequestName", $$v)
                              },
                              expression: "data.approvalRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      staticStyle: { "margin-top": "-15px !important" },
                    },
                    [
                      _c("c-table", {
                        ref: "apprline2",
                        attrs: {
                          title: "회람",
                          tableId: "apprline2",
                          topBorderClass: "topcolor-orange",
                          columnSetting: false,
                          isFullScreen: false,
                          hideBottom: true,
                          usePaging: false,
                          filtering: false,
                          isExcelDown: false,
                          gridHeight: "250px",
                          columns: _vm.gridline2.columns,
                          data: _vm.gridline2.data,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      staticStyle: { "margin-top": "-15px !important" },
                    },
                    [
                      _c("c-table", {
                        ref: "apprline",
                        attrs: {
                          title: "결재선",
                          tableId: "apprline",
                          topBorderClass: "topcolor-orange",
                          columnSetting: false,
                          isFullScreen: false,
                          hideBottom: true,
                          usePaging: false,
                          filtering: false,
                          isExcelDown: false,
                          gridHeight: "250px",
                          columns: _vm.gridline.columns,
                          data: _vm.gridline.data,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "customArea",
                            fn: function ({ props, col }) {
                              return [
                                col.name === "approvalKindName"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-weight-bold",
                                          class: _vm.getLineKindColor(
                                            props.row.approvalKindCd
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.approvalKindName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                col.name === "approvalStatusName"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          class: _vm.getLineKindColor2(
                                            props.row.approvalStatusName
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.approvalStatusName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                col.name === "click"
                                  ? [
                                      _c(
                                        "q-btn",
                                        {
                                          attrs: {
                                            round: "",
                                            unelevated: "",
                                            size: "10px",
                                            color: "amber",
                                            icon: "search",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (() => {
                                                ;(_vm.rowIndex =
                                                  props.rowIndex),
                                                  (_vm.electronSignature =
                                                    props.row.electronSignature)
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "q-popup-proxy",
                                            {
                                              ref: "proxy1_" + props.rowIndex,
                                              attrs: { breakpoint: 400 },
                                            },
                                            [
                                              _c(col.component, {
                                                tag: "component",
                                                attrs: {
                                                  popupParam: props.row,
                                                  rowIndex: props.rowIndex,
                                                },
                                                on: { callback: _vm.callback1 },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm displayBlockCard",
                attrs: { title: "결재 상세내용", topClass: "topcolor-orange" },
              },
              [
                _c(
                  "template",
                  { slot: "card-detail" },
                  [
                    _c(_vm.component, {
                      tag: "component",
                      attrs: { popupParam: _vm.data.approvalParamValue },
                      on: {
                        "update:popupParam": function ($event) {
                          return _vm.$set(
                            _vm.data,
                            "approvalParamValue",
                            $event
                          )
                        },
                        "update:popup-param": function ($event) {
                          return _vm.$set(
                            _vm.data,
                            "approvalParamValue",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "서명란" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.editable,
                                    expression: "editable",
                                  },
                                ],
                                attrs: { label: "결재", icon: "save" },
                                on: { btnClicked: _vm.saveDialog },
                              }),
                              _c("c-btn", {
                                attrs: {
                                  label: "지우기",
                                  color: "red",
                                  showLoading: false,
                                },
                                on: { btnClicked: _vm.eraseSignature },
                              }),
                              _c("c-btn", {
                                attrs: { label: "취소", icon: "cancel" },
                                on: { btnClicked: _vm.closeDialog },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-sm-12" },
                            [
                              _c("VueSignaturePad", {
                                ref: "signaturePad",
                                attrs: { width: "300px", height: "300px" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }